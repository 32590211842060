import React from 'react'
import './Join.css'
import { useRef } from 'react'
 import emails from '@emailjs/browser'
const Join = () => {
    const form = useRef()

    // const sendEmail = (e)=>{
    //     e.preventDefault();
        
    //     emailjs.sendForm('service_xxxx' , 'template_xxx' form.current,'Your_user_id_xxxx')
    //     .then((result)=>{
    //         console.log(result.text);
    //     }, (error)=>{
    //         console.log(error.text)
    //     })
    // }
  return (
    <div className="join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroks-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroks-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form /*ref={form}*/ className="email-container" /*onSubmit={sendEmail} */>
                <input type="email" name="user_email" placeholder='Enter your email address'/>
                <button className="btn btn-j">Join Now</button>
            </form>  
        </div>
    </div>
  )
}

export default Join